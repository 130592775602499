import React from 'react'
import { ImagePack } from '../constants'
import { Container, Row, Col } from 'reactstrap'
import './errorpage.scss'
import { Link } from 'react-router-dom'
import {
  RiSendPlaneLine,
  RiLinkedinFill,
  RiGithubLine,
  RiTwitterLine,
} from 'react-icons/ri'

const ErrorPage = () => {
  return (
    <section className="error">
      <Container>
        <Row>
          <Col lg="6" md="6" sm="12" className="d-flex justify-content-center">
            <div>
              {/* <h1> We ran into an Error !!</h1> */}
              <h1> Site Currently Under Maintenance</h1>
              <p>
                We ran into an issue processing your request, our developers are
                fixing this issue
              </p>
              {/* <button>Report Issue</button>
              <button>Take me to homepage</button> */}
              <button>While waiting</button>

              {/* Extracts From Footer / footer.scss */}
              <div className=" d-flex mt-3  gap-2 social-icons">
                <h5 className="m-0">Follow:</h5>
                <span>
                  <Link to="https://twitter.com/xeraxlabs" target="_blank">
                    <RiTwitterLine />
                  </Link>
                </span>
                <span>
                  <Link to="https://github.com/Xeraxlabs" target="_blank">
                    <RiGithubLine />
                  </Link>
                </span>
                <span>
                  <Link
                    to="https://www.linkedin.com/in/akinwumidi/"
                    target="_blank"
                  >
                    <RiLinkedinFill />
                  </Link>
                </span>
              </div>
            </div>
          </Col>
          <Col lg="6" md="6" sm="12">
            <img src={ImagePack.e404} alt="Error illustration" />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ErrorPage
