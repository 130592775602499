import React from 'react'
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import { ImagePack } from '../../constants'
import './footer.scss'
import { Link } from 'react-router-dom'
import {
  RiSendPlaneLine,
  RiLinkedinFill,
  RiGithubLine,
  RiTwitterLine,
} from 'react-icons/ri'

const Footer = () => {
  return (
    <section className="footer">
      <img
        src={ImagePack.soundwaves}
        alt="page decoratives"
        className="tr-decoratives"
      />
      <Container>
        <Row>
          <Col lg="3" md="4" sm="6">
            <div className="footer__brand">
              <img src={ImagePack.xeraxlogo} alt="Company logo" />
              <span className="brand-title">
                Xerax<span>labs</span>
              </span>
            </div>
            <p className="footer__company-brief">
              Xerax Labs Inc (Xerax labs), Is a Nigerian owned Software
              development company that offers a wide range of products, It
              focuses on software development, E-commerce and Computer motion
              graphics at large
            </p>
          </Col>

          <Col lg="3" md="4" sm="6">
            <h5 className="footer__title">company</h5>
            <span>
              <Link to="/">About Xeraxlabs</Link>
            </span>
            <span>
              <Link to="/">Our Services</Link>
            </span>
            <span>
              <Link to="/">Contact Us</Link>
            </span>
            <span>
              <Link to="/">Our Technology</Link>
            </span>
            <span>
              <Link to="/">Blog</Link>
            </span>
            <span>
              <Link to="/">Career</Link>
            </span>
            <h5 className="footer__title">contest</h5>
            <span>
              <Link to="/">Hackathons</Link>
            </span>
            <span>
              <Link to="/">Awards</Link>
            </span>
            <span>
              <Link to="/">Newsroom</Link>
            </span>
            <span>
              <Link to="/">Sitemap</Link>
            </span>
          </Col>

          <Col lg="3" md="4" sm="6">
            <h5 className="footer__title">Quck Contact</h5>
            <ListGroup className=" time-list">
              <ListGroupItem className="border-0 p-0">
                <span>
                  Location: 13 Kola Adeyemi Street, Akure, Ondo State, Nigeria
                  340543
                </span>
              </ListGroupItem>
              <ListGroupItem className="border-0 p-0">
                <span>Phone: +234 (815) 831-6730</span>
                <br />
                <span>Phone: +234 (902) 005-6187</span>
              </ListGroupItem>

              <ListGroupItem className="border-0 p-0">
                <span>
                  <Link to="mailto:suppport@xeraxlabs.com" target="_blank">
                    Email: suppport@xeraxlabs.com{' '}
                  </Link>
                </span>
              </ListGroupItem>
            </ListGroup>

            <h5 className="footer__title">Legal</h5>
            <span>
              <Link to="/">Privacy Policy</Link>
            </span>
            <span>
              <Link to="/">Terms of Service</Link>
            </span>
          </Col>

          <Col lg="3" md="4" sm="6">
            <h5 className="footer__title">Newsletter</h5>
            <p>Subscribe to our newsletter</p>

            <div className="newsletter">
              <input type="email" placeholder="Enter your email"></input>
              <span>
                <RiSendPlaneLine />
              </span>
            </div>

            <div className=" d-flex mt-3  gap-2 social-icons">
              <p className="m-0">Follow:</p>
              <span>
                <Link to="https://twitter.com/xeraxlabs" target="_blank">
                  <RiTwitterLine />
                </Link>
              </span>
              <span>
                <Link to="https://github.com/Xeraxlabs" target="_blank">
                  <RiGithubLine />
                </Link>
              </span>
              <span>
                <Link
                  to="https://www.linkedin.com/in/akinwumidi/"
                  target="_blank"
                >
                  <RiLinkedinFill />
                </Link>
              </span>
            </div>
          </Col>
        </Row>
        <p className="footer-copyright">
          copyright &copy; 2020 - 2023 Xerax Labs Ltd ( xeraxlabs ) - Endless
          Possibilities ...
        </p>
      </Container>
    </section>
  )
}

export default Footer
