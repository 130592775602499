import xeraxlogo from "../assets/images/xeraxlogo.svg"
import dotted from "../assets/images/idotted.png"
import soundwaves from "../assets/images/isound.png"
import patterns from "../assets/images/ibGroup.png"
import bottle from "../assets/images/animatedbottle.gif"
import codebrackets from "../assets/images/animatedcodebrackets.gif"
import Maintenance from "../assets/images/Maintenance.gif"
import e404 from "../assets/images/e404.svg"
import lantern from "../assets/images/lantern.svg"



export default {
    xeraxlogo,
    dotted, soundwaves, patterns, bottle, codebrackets, Maintenance, e404, lantern
}