import React from 'react'

import Countdown from 'react-countdown'

const CountdownTimer = () => {
  return (
    <Countdown
      date="2023-3-31"
      daysInHours="true"
      className="countdown-timer"
    />
  )
}

export default CountdownTimer
