import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom"
import Home from "../pages/Home"
import Maintenance from '../pages/Maintenance'
import ErrorPage from '../pages/ErrorPage'


const Routers = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Navigate to="/maintenance" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/maintenance" element={<Maintenance />} />
                <Route path="/error" element={<ErrorPage />} />
            </Routes>
        </div>
    )
}

export default Routers
