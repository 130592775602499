import React from 'react'
import './header.scss'
import { Link } from 'react-router-dom'
import { RiCompassesFill, RiUser6Line } from 'react-icons/ri'
import { ImagePack } from '../../constants'

const headerLinks = [
  { name: 'Services', path: '' },
  { name: 'Academy', path: '' },
  { name: 'hackathons', path: '' },
  { name: 'Blog', path: '' },
]
const Header = () => {
  return (
    <nav className="header">
      <div className="nav__brand">
        <img src={ImagePack.xeraxlogo} alt="Company logo" />
        <span className="brand-title">
          Xerax<span>labs</span>
        </span>
      </div>
      <ul className="header__links-container">
        {headerLinks.map((link, index) => (
          <li key={index} className="header-links">
            <Link>{link.name}</Link>
          </li>
        ))}
      </ul>
      <div className="header-actions">
        <Link>
          <button className="styled-buttons">
            <span className="s-overlay"></span>
            <span className="s-overlay2">
              Hire us <RiCompassesFill />
            </span>
          </button>
        </Link>
        <Link>
          <button className="styled-buttons">
            <span className="s-overlay"></span>
            <span className="s-overlay2">
              My account <RiUser6Line />
            </span>
          </button>
        </Link>
      </div>
    </nav>
  )
}

export default Header
