import React from 'react'
import { ImagePack } from '../constants'
import { Container, Row, Col } from 'reactstrap'
import './maintenance.scss'
import { Link } from 'react-router-dom'
import CountdownTimer from '../components/CountdownTimer/CountdownTimer'
import {
  RiSendPlaneLine,
  RiLinkedinFill,
  RiGithubLine,
  RiTwitterLine,
} from 'react-icons/ri'

const Maintenance = () => {
  return (
    <section className="maintenance">
      <Container>
        <Row>
          <Col lg="6" md="6" sm="12" className="d-flex justify-content-center">
            <div>
              <h1>
               Site currently <br />
                under maintenace
              </h1>
              <p>
                Check Back in: <CountdownTimer />
              </p>
              <p>Our developers are fixing this issue</p>

              <button>Take me to homepage</button>

              {/* Extracts From footer / footer.scss */}
              <div className=" d-flex mt-3  gap-2 social-icons">
                <p className="m-0">Follow:</p>
                <span>
                  <Link to="https://twitter.com/xeraxlabs" target="_blank">
                    <RiTwitterLine />
                  </Link>
                </span>
                <span>
                  <Link to="https://github.com/Xeraxlabs" target="_blank">
                    <RiGithubLine />
                  </Link>
                </span>
                <span>
                  <Link
                    to="https://www.linkedin.com/in/akinwumidi/"
                    target="_blank"
                  >
                    <RiLinkedinFill />
                  </Link>
                </span>
              </div>
            </div>
          </Col>
          <Col lg="6" md="6" sm="12">
            <img
              src={ImagePack.lantern}
              alt="site under maintainance illustration"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Maintenance
