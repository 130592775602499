import React from 'react'
import { Header, Footer, SideNav } from "../index"
import Routes from "../../routes/Routers"

import ProgressBar from "react-progressbar-on-scroll"
import "./layout.scss"

const Layout = () => {
  return (<>
    <div className='app__wrapper'>
      {/* <SideNav /> */}
      <div className='main__content-tab'>
        <Header />
        <ProgressBar
          color="#4175fc"
          gradient={true}
          gradientColor="#084af3"
          height={5}
        />
        <div>
          <Routes />
        </div>
      </div>

    </div>
    <Footer />
  </>

  )
}

export default Layout
